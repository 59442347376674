<template>
	<div class="content">
		<section class="head-section">
			<div class="container cols-2">
				<div class="content">
					<h1 class="title" 
						data-aos="fade-right"
						data-aos-duration="1000"
						data-aos-delay="300">Траурна флористика</h1>
					<p
						data-aos="fade-right"
						data-aos-duration="1000"
						data-aos-delay="500">Створюємо квіткові композиції на похорон – це екологічна альтернатива пластиковим вінкам. Такий вид флористики має певні правила, тому краще звернутися до професіоналів, щоб композиція виглядала доречною. Наші дизайнери складуть правильний букет або кошик, підберуть спеціальні квіти у необхідній кольоровій гамі.</p>
				</div>
				<div class="images-col">
					
					
					<picture class="full-image" 
					data-aos="fade-left"
					data-aos-duration="1000"
					data-aos-delay="300">
						<source srcset="../assets/images/mourning-1.webp" type="image/webp">
						<img src="../assets/images/mourning-1.jpg" alt="Mourning Flowers" :ref="setImagemRef">
					</picture>
					<picture class="hover-image"
					data-aos="fade-left"
					data-aos-duration="1000">
						<source srcset="../assets/images/mourning-2.webp" type="image/webp">
						<img src="../assets/images/mourning-2.jpg"  alt="Mourning Flowers" :ref="setImagemRef">
					</picture>
				</div>
				
			</div>
		</section>
		<section class="section-gallery">
			<div class="container">
				<div class="gallery">
					<div v-for="(img, index) in gallery"
					:key="index" :class="'pic'"
					@click="() => showGalleryImg(index)">
						<picture>
							<source :srcset="typeof img === 'string' ? img : img.webp" type="image/webp">
							<img :src="typeof img === 'string' ? img : img.thumbnail"  alt="Mourning Flowers" :ref="setImagemRef">
						</picture>
					</div>
				</div>
				<vue-easy-lightbox
					:visible="visibleImg"
					:imgs="gallery"
					:index="indexGallery"
					:moveDisabled = "moveDisabled"
					:scrollDisabled = "scrollDisabled"
					:swipeTolerance = "swipeTolerance"
					:escDisabled="escDisabled"
					:loop="loop"
					@hide="handleGalleryHide">
						
					</vue-easy-lightbox>
			</div>
		</section>
		<section>
			<div class="container">
				<v-form/>
			</div>
		</section>
		<section>
			<v-contacts/>
		</section>
	</div>
</template>
<style scoped lang="scss">
	@import "../assets/scss/components/mourning-page.scss";
</style>

<script>
	import VueEasyLightbox from 'vue-easy-lightbox' 
	import ContactForm from '@/components/ContactForm.vue'
	import Contacts from '@/components/Contacts.vue'

	import { ref, onMounted, onBeforeUpdate } from 'vue'


	export default {
		setup() {
			let imageRefs = ref([])
			const setImagemRef = el => {
				if (el) {
					imageRefs.value.push(el)
				}
			}
		onMounted(() => {
			imageRefs.value.forEach(function(entry) {
				entry.width = entry.clientWidth
				entry.height = entry.clientHeight
			});
		})
		onBeforeUpdate(() => {
			imageRefs.value = []

		})
			return {
				setImagemRef
			}
		},
		components: {
			VueEasyLightbox,
			'v-contacts': Contacts,
			'v-form': ContactForm
		},
		data(){
			return {
				list:[
					{
						title:'З 1-го лютого до 15 квітня',
						descr: 'Чекаємо Ваші кашпо, горщики, ящики у нашому садовому центрі'
					},
					{
						title:'Вибір рослин',
						descr: 'В нас широкий асортимент для озеленення балконів та терас'
					},
					{
						title:'Оформлення замовлення',
						descr: ''
					},
					{
						title:'Вирощування в парнику',
						descr: 'В нас створені всі умови для швидкого та правильно вирощування ваших рослин'
					},
					{
						title:'До 10 травня (по готовності)',
						descr: 'Забираєте Ваші готові замовлення'
					}
				],
				visibleImg: false,
				indexGallery: 0,
				moveDisabled:true,
				scrollDisabled : true,
				swipeTolerance : 50,
				escDisabled : true,
				loop: true,
				gallery: [
				{
					src: require('../assets/images/mourning-gallery/image-1.jpg'),
					webp: require('../assets/images/mourning-gallery/small-1.webp'),
					thumbnail: require('../assets/images/mourning-gallery/small-1.jpg') 
				},
				{
					src: require('../assets/images/mourning-gallery/image-2.jpg'),
					webp: require('../assets/images/mourning-gallery/small-2.webp'),
					thumbnail: require('../assets/images/mourning-gallery/small-2.jpg') 
				},
				{
					src: require('../assets/images/mourning-gallery/image-3.jpg'),
					webp: require('../assets/images/mourning-gallery/small-3.webp'),
					thumbnail: require('../assets/images/mourning-gallery/small-3.jpg') 
				},
				{
					src: require('../assets/images/mourning-gallery/image-4.jpg'),
					webp: require('../assets/images/mourning-gallery/small-4.webp'),
					thumbnail: require('../assets/images/mourning-gallery/small-4.jpg') 
				},
				{
					src: require('../assets/images/mourning-gallery/image-5.jpg'),
					webp: require('../assets/images/mourning-gallery/small-5.webp'),
					thumbnail: require('../assets/images/mourning-gallery/small-5.jpg') 
				},
				{
					src: require('../assets/images/mourning-gallery/image-6.jpg'),
					webp: require('../assets/images/mourning-gallery/small-6.webp'),
					thumbnail: require('../assets/images/mourning-gallery/small-6.jpg') 
				},
				{
					src: require('../assets/images/mourning-gallery/image-7.jpg'),
					webp: require('../assets/images/mourning-gallery/small-7.webp'),
					thumbnail: require('../assets/images/mourning-gallery/small-7.jpg') 
				}

			]
			} 
		},
		methods: {
			showGalleryImg(index) {
				this.indexGallery = index
				this.visibleImg = true
			},
			handleGalleryHide() {
				this.visibleImg = false
			}
		}
	}
</script>
